import React, { useState, useEffect } from 'react'
import Amplify from '@aws-amplify/core'
import { withTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import { LocationProps } from '@reach/router'
import { graphql, navigate, Link } from 'gatsby'
// import { detect } from 'detect-browser'
import Layout from '../layouts/defaultLayout'
import awsConfig from './../aws-exports'
import { FONTS, HASH_SIGN_IN, ROUTES } from '../constants'
// import { trackAddToChirpyestButtonClickEvent } from '../../utils/trackingEvents'
import { styles } from '../pagesStyles/homeStyles'
import CHRSectionContainer from './../components/sectionContainer'
import CHROutlinedButton from './../components/outlinedButton'
import ContentContainer from './../components/contentContainer'
import CHRFloatImageLeft from '../components/floatImageLeft'
import CHRFloatImageRight from '../components/floatImagRight'
import AddExtensionPopup from '../components/popups/automaticPopOver/addExtensionPopUp'
import PinExtensionPopup from '../components/popups/automaticPopOver/pinExtensionPopup'
import CHRArrowButton from '../components/arrowButton'
import chromeLogo from '../assets/images/chrome.svg'
// import safariLogo from '../assets/images/safari.svg'
import { isUserAuthenticated } from './../../utils/isUserAuthenticated'
import { useAppState } from '../appState'
import ExtensionWarningModal from '../components/extensionWarningModal'
import useNavigateToExtStore from '../hooks/useNavigateToExtStore'
import SEO from '../components/seo'
import Links from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
Amplify.configure(awsConfig)

interface HowItWorksPageProps {
  data: any
  t: TFunction
  location: LocationProps
}

const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const HowItWorksPage = ({ data, t, location }: HowItWorksPageProps) => {
  const classes = styles()
  const [isAddExtensionPopUpOpen, setIsAddExtensionPopUpOpen] = useState(false)
  const [isPinExtensionPopUpOpen, setIsPinExtensionPopUpOpen] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [appState, dispatch] = useAppState()
  const [
    isExtWarningModalOpen,
    setExtWarningModalOpen,
    navigateToStore,
  ] = useNavigateToExtStore()

  const centeredSection = classNames({
    [classes.centered]: true,
  })

  useEffect(() => {
    isUserAuthenticated().then(res => {
      setIsLoggedIn(res)
    })
  }, [])

  useEffect(() => {
    let pageIndex = Math.floor(Math.random() * 4)
    if (
      !window.localStorage.getItem('extensionDownloaded') &&
      pageIndex === 2
    ) {
      setIsAddExtensionPopUpOpen(true)
    }

    if (
      window.localStorage.getItem('firstMove') &&
      window.localStorage.getItem('extensionDownloaded')
    ) {
      setIsPinExtensionPopUpOpen(true)
      window.localStorage.removeItem('firstMove')
    }
  }, [])

  return (
    <Layout openSignInModal={location.hash.includes(HASH_SIGN_IN)}>
      <SEO
        title={t('seo.howItWorks.title')}
        description={t('seo.howItWorks.description')}
      />
      <ContentContainer>
        <div className={classes.breadcrumb}>
          <Breadcrumbs aria-label="breadcrumb">
            <Links
              color="inherit"
              rel="preconnect"
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => {
                event.preventDefault()
                navigate(ROUTES.home)
              }}
            >
              home
            </Links>
            <Typography color="textPrimary">how it works</Typography>
          </Breadcrumbs>
        </div>
      </ContentContainer>

      <ContentContainer>
        {/* <Typography variant="h1" align="left" className={classes.headingText}>
          {t('howItWorks.heading')}
        </Typography> */}
        <span className={classes.howItWorksHeading}>
          {t('howItWorks.heading')}
        </span>
      </ContentContainer>
      <ContentContainer>
        <Typography variant="h1" align="left" className={classes.seoHeading}>
          {t('seo.howItWorks.h1')}
        </Typography>
      </ContentContainer>
      {/*------------ shop millions of products + earn cash back ------------*/}
      <section>
        <CHRFloatImageLeft
          rightChildren={
            <div>
              <Typography
                variant="h2"
                className={[classes.customWidth4, classes.customWidth6].join(
                  ' '
                )}
              >
                {t('whyJoin.shopMillionsOfProductsAndEarn')}
              </Typography>
              <div>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.customWidth4}
                >
                  {t('whyJoin.shopMillionsOfProductsIn')}{' '}
                  <Link
                    to="/category/beauty"
                    className={`${classes.underLinedText} ${classes.clickable}`}
                  >
                    {t('shared.beauty')}
                  </Link>
                  ,{' '}
                  <Link
                    to="/category/fashion"
                    className={`${classes.underLinedText} ${classes.clickable}`}
                  >
                    {t('shared.fashion')}
                  </Link>
                  ,{' '}
                  <Link
                    to="/category/home"
                    className={`${classes.underLinedText} ${classes.clickable}`}
                  >
                    {t('shared.home')}
                  </Link>{' '}
                  {t('shared.and')}{' '}
                  <Link
                    to="/category/lifestyle"
                    className={`${classes.underLinedText} ${classes.clickable}`}
                  >
                    {t('shared.lifestyle')}
                  </Link>{' '}
                  {t('shared.from')}{' '}
                  <Link
                    to="/brands"
                    className={`${classes.underLinedText} ${classes.clickable}`}
                  >
                    {t('shared.topBrands')}
                  </Link>
                  . {t('whyJoin.allCuratedBy')}{' '}
                  <Link
                    to="#"
                    className={`${classes.underLinedText} ${classes.clickable}`}
                  >
                    {t('shared.chirpyestStyleFinders')}
                  </Link>
                  .
                </Typography>
              </div>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.customWidth4}
              >
                {t('whyJoin.asChirpyestMember')}
              </Typography>
              <div>
                <div
                  className={classes.floatRightButton}
                  style={{ float: 'right' }}
                >
                  <CHRArrowButton
                    onClick={() => navigate('/shop')}
                    label={t('shared.shop')}
                  />
                </div>
              </div>
            </div>
          }
          isGatsbyImg={true}
          image={data.allImageSharp.edges[8].node.fluid}
          withBackground
          withoutButton
          mediaHasBg
        />
      </section>
      {/*------------ add the chirpyest extension to your browser ------------*/}
      <CHRSectionContainer>
        <section>
          <CHRFloatImageRight
            image={[
              data.allImageSharp.edges[7].node.fluid,
              {
                ...data.allImageSharp.edges[4].node.fluid,
                media: `(min-width: 1024px)`,
              },
            ]}
            isGatsbyImg={true}
            leftChildren={
              <div>
                <Typography
                  variant="h2"
                  className={[classes.customWidth3, classes.customWidth7].join(
                    ' '
                  )}
                >
                  {t('howItWorks.steps.addTheChirpyest')}{' '}
                  <img
                    src={chromeLogo}
                    alt={t('shared.chirpyest')}
                    className={classes.browserLogo}
                  />
                  {/* <img
                    src={safariLogo}
                    alt={t('shared.chirpyest')}
                    className={classes.browserLogo}
                  /> */}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={[classes.customWidth, classes.desktopView].join(
                    ' '
                  )}
                >
                  {t('howItWorks.theQuickest')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={[classes.customWidth, classes.mobileView].join(
                    ' '
                  )}
                >
                  {t('howItWorks.onYourLaptop')}
                </Typography>
                <div className={classes.floatRightButton}>
                  <div>
                    <CHRArrowButton
                      label={t('howItWorks.addOurExtension')}
                      onClick={() => navigate(ROUTES.browserExtension)}
                    />
                  </div>
                </div>
              </div>
            }
            withBackground
            withoutButton
            mediaHasBg
          />
        </section>
      </CHRSectionContainer>
      {/*------------ share and earn more cash back! ------------*/}
      <CHRSectionContainer>
        <section>
          <CHRFloatImageLeft
            rightChildren={
              <div>
                <Typography
                  variant="h2"
                  className={[classes.customWidth4, classes.customWidth6].join(
                    ' '
                  )}
                >
                  {t('whyJoin.shareAndEarn')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.customWidth5}
                >
                  {t('whyJoin.okayHereIsTheBigTwist')}
                </Typography>
                <div>
                  <div
                    className={classes.floatRightButton}
                    style={{ float: 'right' }}
                  >
                    <CHRArrowButton
                      onClick={() => navigate(ROUTES.brands)}
                      label={t('howItWorks.exploreOurBrands')}
                    />
                  </div>
                </div>
              </div>
            }
            isGatsbyImg={true}
            image={[
              data.allImageSharp.edges[2].node.fluid,
              {
                ...data.allImageSharp.edges[3].node.fluid,
                media: `(min-width: 1024px)`,
              },
            ]}
            withBackground
            withoutButton
            mediaHasBg
          />
        </section>
      </CHRSectionContainer>
      {/*------------ earn cash back from your shopping board ------------*/}
      <CHRSectionContainer>
        <section>
          <CHRFloatImageRight
            leftChildren={
              <div className={classes.customWidth}>
                <Typography variant="h2">
                  {t('whyJoin.earnCashBack')}
                </Typography>
                <Typography variant="subtitle1" component="p">
                  {t('whyJoin.saveItems')}
                </Typography>
                <div>
                  <div className={classes.floatRightButton}>
                    <CHRArrowButton
                      label={t('howItWorks.createBoard')}
                      onClick={() => {
                        !isLoggedIn
                          ? navigate(ROUTES.join)
                          : navigate(
                              ROUTES.mainBoard.replace(
                                ':userName',
                                appState.username
                              )
                            )
                      }}
                    />
                  </div>
                </div>
              </div>
            }
            isGatsbyImg={true}
            image={data.allImageSharp.edges[9].node.fluid}
            withoutButton
          />
        </section>
      </CHRSectionContainer>
      {/*------------ shop the chirpyest’s curated style finds ------------*/}
      <CHRSectionContainer>
        <section>
          <CHRFloatImageLeft
            rightChildren={
              <div>
                <Typography
                  variant="h2"
                  className={[classes.customWidth, classes.customWidth6].join(
                    ' '
                  )}
                >
                  {t('whyJoin.shopChirpyestCurated')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={[classes.customWidth].join(' ')}
                >
                  {t('whyJoin.followChirpyestMembers')}
                </Typography>
                <div>
                  <div
                    className={classes.floatRightButton}
                    style={{ float: 'right' }}
                  >
                    <CHRArrowButton
                      label={t('shared.theChirpyest')}
                      onClick={() => navigate(ROUTES.theChirpyest)}
                    />
                  </div>
                </div>
              </div>
            }
            isGatsbyImg={true}
            image={data.allImageSharp.edges[0].node.fluid}
            withBackground
            withoutButton
            mediaHasBg
          />
        </section>
      </CHRSectionContainer>

      {/*------------ Get inspiration on our blog and to your inbox! ------------*/}
      <CHRSectionContainer>
        <section>
          <CHRFloatImageRight
            leftChildren={
              <div>
                <Typography
                  variant="h2"
                  className={[classes.customWidth4, classes.customWidth7].join(
                    ' '
                  )}
                >
                  {t('whyJoin.getInspiration')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.customWidth}
                >
                  {t('whyJoin.exploreOurFeaturedFeeds')}
                </Typography>
                <div>
                  <div className={classes.floatRightButton}>
                    <CHRArrowButton
                      label={t('howItWorks.exploreTheBlog')}
                      onClick={() => navigate(ROUTES.blog)}
                    />
                  </div>
                </div>
              </div>
            }
            isGatsbyImg={true}
            image={data.allImageSharp.edges[5].node.fluid}
            withBackground
            withoutButton
            mediaHasBg
          />
        </section>
      </CHRSectionContainer>

      {/*------------ chirpyest works on mobile too ------------*/}
      <CHRSectionContainer>
        <section>
          <CHRFloatImageLeft
            rightChildren={
              <div>
                <Typography
                  variant="h2"
                  className={[classes.customWidth4, classes.customWidth6].join(
                    ' '
                  )}
                >
                  {t('whyJoin.chirpyestWorksOnMobile')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.customWidth5}
                >
                  {t('whyJoin.browseProduct')}
                </Typography>
                <div className={classes.mobileView}>
                  <div
                    className={classes.floatRightButton}
                    style={{ float: 'right' }}
                  >
                    <CHRArrowButton
                      onClick={() => navigate(ROUTES.brands)}
                      label={t('howItWorks.exploreOurBrands')}
                    />
                  </div>
                </div>
              </div>
            }
            isGatsbyImg={true}
            image={[
              data.allImageSharp.edges[6].node.fluid,
              {
                ...data.allImageSharp.edges[1].node.fluid,
                media: `(min-width: 1024px)`,
              },
            ]}
            withBackground
            withoutButton
            mediaHasBg
          />
        </section>
      </CHRSectionContainer>
      {/*------------ What are you waiting for ------------*/}
      <CHRSectionContainer>
        <ContentContainer>
          <section className={centeredSection}>
            <Typography variant="h1" component="h2">
              {t('home.whatAreYouWaiting')}
            </Typography>
            <CHROutlinedButton
              label={
                isLoggedIn
                  ? t('shared.startShopping')
                  : t('shared.joinChirpyest')
              }
              font={FONTS.Graphik.GraphikMedium}
              onClick={() => {
                isLoggedIn ? navigate('/shop') : navigate(ROUTES.join)
              }}
            />
          </section>
        </ContentContainer>
      </CHRSectionContainer>
      <AddExtensionPopup
        open={isAddExtensionPopUpOpen}
        handleClose={() => setIsAddExtensionPopUpOpen(false)}
      />
      <PinExtensionPopup
        open={isPinExtensionPopUpOpen}
        handleClose={() => setIsPinExtensionPopUpOpen(false)}
      />
      <ExtensionWarningModal
        isOpen={isExtWarningModalOpen}
        onClose={() => setExtWarningModalOpen(false)}
      />
    </Layout>
  )
}

export default withTranslation()(HowItWorksPage)

export const query = graphql`
  query {
    allImageSharp(
      filter: {
        fluid: {
          originalName: {
            in: [
              "shop-millions-of-products.png"
              "create-share-link.png"
              "your-shopping-board.png"
              "chirpyest-curated-style-finds.png"
              "inspiration-on-our-blog.png"
              "chirpyest-works-on-mobile.png"
              "mobile-chirpyest-works-on-mobile.png"
              "mobile-how-it-works-extension.png"
              "how-it-works-extension.png"
              "create-share-link-mobile.png"
            ]
          }
        }
      }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
